<template>
<div>
    <v-row align="center" justify="center" class="mx-1 mb-3">
        <v-col cols="12" md="2">
            <v-autocomplete return-object @input="getItems()" @click:clear="()=> {filter.pharmacyId = '', getItems()}" v-model="filter.pharmacyId" v-debounce:500ms="getPharmacies" :search-input.sync="searchPharmacy" clearable item-text="fullName" item-value="id" dense :items="pharmacies" class="mr-1" :label="$route.meta.type" :loading="loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-autocomplete return-object @input="getItems()" @click:clear="()=> {filter.regionId = '', getItems()}" v-model="filter.regionId" v-debounce:500ms="getRegions" :search-input.sync="searchRegion" clearable item-text="name" item-value="id" dense :items="regions" class="mr-1" label="Region" :loading="loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <v-autocomplete return-object @input="getItems()" @click:clear="()=> {filter.companyId = '', getItems()}" v-model="filter.companyId" v-debounce:500ms="getCompanies" :search-input.sync="searchCompany" clearable item-text="name" item-value="id" dense :items="companies" class="mr-1" label="Company" :loading="loading" no-data-text="لا توجد بيانات" hide-details="" outlined="" filled="" />
        </v-col>

        <v-col cols="12" md="2">
            <!-- <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.search" label="بحث حسب إسم الزبون او رقم هاتفه" hide-details="auto" prepend-inner-icon="search" outlined="" filled="" /> -->
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filter.from" dense label="From" outlined hide-details="auto" filled append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="filter.from" @input="()=> {getItems(), menu = false}" locale="ar"></v-date-picker>
            </v-menu>
        </v-col>
        <v-col cols="12" md="2">
            <!-- <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="filter.search" label="بحث حسب إسم الزبون او رقم هاتفه" hide-details="auto" prepend-inner-icon="search" outlined="" filled="" /> -->
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filter.to" label="To" dense outlined hide-details="auto" filled append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="filter.to" @input="()=> {getItems(), menu2 = false}" locale="ar"></v-date-picker>
            </v-menu>
        </v-col>
        <v-col cols="12" v-if="items.length > 0">
            <v-divider />
            <v-row class="my-3 mx-2">
                <h3>TOTAL ORDERS: <span class="primary--text">( {{items.length}} )</span></h3>
                <v-divider class="mx-4" vertical />
                <h3>TOTAL AMOUNTS: <span class="primary--text">( {{$service.formatCurrency(items.map(item => item.totalPriceAfterDiscount).reduce((prev, next) => prev + next))}} )</span></h3>
                <v-divider class="mx-4" vertical />
                <h3>TOTAL REMAINING: <span class="primary--text">( {{$service.formatCurrency(items.map(item => item.totalPriceAfterDiscount).reduce((prev, next) => prev + next) - items.map(item => item.amountReceived).reduce((prev, next) => prev + next) - items.map(item => item.cashDiscount).reduce((prev, next) => prev + next) )}} )</span></h3>
            </v-row>

            <v-divider />
        </v-col>

    </v-row>
    <v-card>
        <v-btn @click="getExcelFile()" :loading="downloading" large color="success" class="ma-2">
            <v-icon class="ml-2">mdi-download</v-icon>
            <h3>تحميل ملف Excel Sheet</h3>
        </v-btn>
        <v-btn v-print="'#printMe'" large color="primary" class="ma-2">
            <v-icon class="ml-2">mdi-download</v-icon>
            <h3>طباعة</h3>
        </v-btn>

        <div >
            <OrdersUnpaidPrint class="onlyPrint" id="printMe" :filter="filter" :data='items' />
        </div>

        <v-data-table :items-per-page="filter.pageSize" :headers="headers" :items="items" :loading="loading" hide-default-footer>
            <template v-slot:item.created="{item}">
                <div dir="ltr">{{item.created.substr(0,10)}}</div>
            </template>
            <template v-slot:item.remainingAmount="{item}">
                <div dir="ltr">{{$service.formatCurrency(item.totalPriceAfterDiscount - item.amountReceived - item.cashDiscount)}}</div>
            </template>
            <template v-slot:item.totalPriceAfterDiscount="{item}">
                <div dir="ltr">{{$service.formatCurrency(item.totalPriceAfterDiscount )}}</div>
            </template>
            <template v-slot:item.amountReceived="{item}">
                <div dir="ltr">{{$service.formatCurrency(item.amountReceived )}}</div>
            </template>
            <template v-slot:item.cashDiscount="{item}">
                <div dir="ltr">{{$service.formatCurrency(item.cashDiscount )}}</div>
            </template>
        </v-data-table>
        <!-- <v-pagination @input="getItems()" color="primary" class="py-4" :total-visible="7" v-model="filter.pageIndex" :length="length" /> -->
    </v-card>
</div>
</template>

<script>
import print from 'vue-print-nb'
// import { addDays } from 'date-fns';
export default {
    directives: {
        print
    },
    components: {
        OrdersUnpaidPrint: () => import('./OrdersUnpaidPrint.vue')
    },
    data() {
        return {
            loading: false,
            downloading: false,
            length: 1,
            filter: {
                pageIndex: 1,
                pageSize: 10000,
                search: '',
                pharmacyId: '',
                regionId: '',
                companyId: '',
                from: '',
                to: '',
            },
            items: [],
            pharmacies: [],
            regions: [],
            companies: [],
            searchPharmacy: '',
            searchRegion: '',
            searchCompany: '',
            menu: '',
            menu2: '',

            headers: [{
                    text: 'Order NO',
                    value: 'orderNO',
                    align: 'center'
                },
                {
                    text: 'Created Date',
                    value: 'created',
                    align: 'center'
                },
                {
                    text: 'Pharmacy',
                    value: 'pharmacy.fullName',
                    align: 'center'
                },
                {
                    text: 'Region',
                    value: 'pharmacy.regionName',
                    align: 'center'
                },
                {
                    text: 'Created By',
                    value: 'userCreated.fullName',
                    align: 'center'
                },
                {
                    text: 'Total Price After Discount',
                    value: 'totalPriceAfterDiscount',
                    align: 'center'
                },
                {
                    text: 'Cash Discount',
                    value: 'cashDiscount',
                    align: 'center'
                },
                {
                    text: 'Received Amount',
                    value: 'amountReceived',
                    align: 'center'
                },
                {
                    text: 'Remaining Amount',
                    value: 'remainingAmount',
                    align: 'center'
                },
            ],

        }
    },

    created() {
        if(this.$route.meta.type == 'Pharmacy'){
            this.headers[2] = {
                text: 'Pharmacy',
                value: 'pharmacy.fullName'
            }
            this.headers[3] = {
                text: 'Region',
                value: 'pharmacy.regionName'
            }
        }else if(this.$route.meta.type == 'Doctor'){
            this.headers[2] = {
                text: 'Doctor',
                value: 'doctor.fullName'
            }
            this.headers[3] = {
                text: 'Region',
                value: 'doctor.regionName'
            }
        }else{
            this.headers[2] = {
                text: 'Repository',
                value: 'repository.fullName'
            }
            this.headers[3] = {
                text: 'Region',
                value: 'repository.regionName'
            }
        }
        this.getItems();
        this.getPharmacies();
        this.getRegions();
        this.getCompanies();
    },
 
    methods: {
        async getItems() {
            this.loading = true
            try { 
                let res = await this.$http.get(`${this.$route.meta.reportEndpoint}?PageIndex=${this.filter.pageIndex}&PageSize=${this.filter.pageSize}
                                                &${this.$route.meta.type}Id=${this.filter.pharmacyId ? this.filter.pharmacyId.id : ''}
                                                &RegionId=${this.filter.regionId ? this.filter.regionId.id : ''}
                                                &CompanyId=${this.filter.companyId ? this.filter.companyId.id : ''}
                                                &StartDate=${this.filter.from}&EndDate=${ this.filter.to}`)
                this.items = []
                this.items = res.data.result
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },

        async getPharmacies() {
            this.loading = true
            var endpointType = ''
            if(this.$route.meta.type == 'Pharmacy') {
                endpointType = 'pharmacies'
            } else if (this.$route.meta.type == 'Repository') {
                endpointType = 'Repositories'
            }else{
                endpointType = 'Doctors'
            }
            try {
                let res = await this.$http.get(`${endpointType}?Search=${this.searchPharmacy}`)
                this.pharmacies = res.data.data
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },

        async getRegions() {
            this.loading = true
            try {
                let res = await this.$http.get(`Regions?Search=${this.searchRegion}`)
                this.regions = res.data.result.data
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },

        async getCompanies() {
            this.loading = true
            try {
                let res = await this.$http.get(`Companies`)
                this.companies = res.data.result
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },

        addDays(date, days) {
            date.setDate(date.getDate() + days);
            return date;
        },

        async getExcelFile() {
            try {
                this.downloading = true
                let res = await this.$http.get(`${this.$route.meta.excelEndpoint}?&${this.$route.meta.type}Id=${this.filter.pharmacyId ? this.filter.pharmacyId.id : ''}&RegionId=${this.filter.regionId ? this.filter.regionId.id : ''}&CompanyId=${this.filter.companyId ? this.filter.companyId.id : ''}&startDate=${this.filter.from}&endDate=${this.filter.to}`, {
                    responseType: 'blob'
                })
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'file.xlsx');
                document.body.appendChild(link)
                link.click()
                console.log(res)
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.downloading = false
            }
        },

    }
}
</script>

<style scoped>
@media print {
    .noPrint {
        display: none;
    }
}

@media screen {
    .onlyPrint {
        display: none;
    }
}

@media print
{
h1 {page-break-before:always}
}
</style>
<!-- @page {
    size: A4;
    margin: 1cm 2cm;
    /* use centimeters or inches depending on your needs */
} -->
